export const facebookURL = "https://www.facebook.com/profile.php?id=61561311428889"
export const instagramURL = "https://www.instagram.com/arcticclean.sk/"
export const googleBusinessURL = "https://www.google.com/search?sca_esv=5caaf0c1b0a489d0&sca_upv=1&sxsrf=ADLYWIJnmQOhWUX_tW83Ig3nNw3FFKjuJw%3A1722411234539&q=Arctic%20Clean%20%7C%20Mobiln%C3%A9%20%C4%8Distenie%20such%C3%BDm%20%C4%BEadom&stick=H4sIAAAAAAAAAONgU1I1qDAzT0lNtUwyTEszTTawNEyzMqgwNDE3NjBOMbQ0tUgySjNKW8Rq4FiUXJKZrOCck5qYp1Cj4JuflJmTd3ilwpHezOKS1LzMVIXi0uSMw3tzFY7sS0zJzwUAMhhbhVwAAAA&mat=CYL8sOhogkzx&ved=2ahUKEwiivqru4dCHAxWq8rsIHbBCKzUQrMcEegQIEhAD"
export const email = "info@arcticclean.sk"
export const phone = "+421 915 621 900"
export const name = "Ján Hrin"
export const ico = "53 190 131"
export const dic = "1126349048"
export const address_1 = "Humenné"
export const address_2 = "Radvaň nad Laborcom"

export const openSocial = (type) => {
  switch(type) {
    case "facebook":
      window.open(facebookURL)
      break
    case "instagram":
      window.open(instagramURL)
      break
    case "email":
      // Define email parameters
      const recipient = email;
      const subject = 'ArcticClean - Info';
      const body = 'Dobrý deň, chcel by som sa opýtať ...';

      // Create the mailto link
      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      // Open the mailto link
      window.location.href = mailtoLink;
      break
    case "phone":
      const phoneNumber = phone;
      const telLink = `tel:${phoneNumber}`;
      window.location.href = telLink
      break;
    case "google":
      window.open(googleBusinessURL)
      break;
    default:
      break
  }
}