
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage"
import AboutUsPage from "./pages/AboutUsPage"
import ServicesPage from "./pages/ServicesPage"
/* import BlogPage from "./pages/BlogPage"
import BlogMainPage from "./pages/BlogMainPage" */
import Default from "./layouts/Default"
import { useEffect } from "react";
import TagManager from "react-gtm-module";


function App() {

  const tagManagerArgs = {
    gtmId: 'GTM-WG4V9BBD'
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Default />}>
          <Route index element={<HomePage />} />
          <Route path="o_nas" element={<AboutUsPage />} />
          <Route path="sluzby" element={<ServicesPage />} />
          {/* <Route path="blog" element={<BlogMainPage />} />
          <Route path="blog/:blog_path" element={<BlogPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
