import MapSvg from "./MapSvg"
import { useEffect, useState } from "react"

const MapCard = () => {

  const [showTitle, setShowTitle] = useState(false)
  const [showWrap, setShowWrap] = useState(false)

  useEffect(() => {
      const handleScroll = () => {
        const targetElement = document.getElementById('MAP_CARD_ID')
        const rect = targetElement.getBoundingClientRect()
        const isInViewport = rect.top >= 0 && rect.bottom - 200 <= window.innerHeight

				if (rect.bottom + 500 <= window.innerHeight) {
					setShowTitle(true)
          setShowWrap(true)
				}

        if (isInViewport) {
          /* targetElement.style.minHeight = "40vh" */
          // Optional: Remove the event listener after the alert is triggered to prevent multiple alerts
          setShowTitle(true)
          setShowWrap(true)
          window.removeEventListener('scroll', handleScroll)
        }
      }

		handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
  }
  }, [])


  /* const updateBackgroundHeight = () => {
    const serviceCardWrap = document.getElementById("MAP_CARD_WRAP");
    if (serviceCardWrap) {
      document.getElementById("MAP_CARD_BACKGROUND").style.height = `${serviceCardWrap.offsetHeight}px`;
    }
  }; */
/*
  useEffect(() => {
    updateBackgroundHeight();
  }, [showWrap, showTitle]); */


  return(
    <div className="map_card" id="MAP_CARD_ID">
      {/* <div className="map_card_background" id="MAP_CARD_BACKGROUND"></div> */}
      <div className="map_card_wrap" id="MAP_CARD_WRAP">
        {showWrap && <MapSvg />}
        {
          showTitle &&
          <div className="map_card_descr">
            {/* Pôsobíme prevažne na východnom Slovensku. */}
            Vďaka mobilnému vybaveniu sme schopní pôsobiť kdekoľvek na Slovensku
          </div>
        }
      </div>
    </div>
  )
}

export default MapCard