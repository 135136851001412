import ServicesCard from "../components/ServicesCard"
import ContactUsBannerCard from "../components/ContactUsBannerCard"
import { useEffect } from "react"

const ServicesPage = () => {
  useEffect(() => {
    window.scrollTo(0,0)
    document.getElementsByTagName("META")[4].content =`Ponuke čistenie suchým ľadom a prípravu áut na predaj, v blízkej budúcnosti ale plánujeme rozšíriť našu ponuku o tryskanie pieskom a čistenie vysokotlakovými čističmi.`
    document.getElementsByTagName("LINK")[4].href =`https://www.arcticclean.sk/sluzby`
  }, [])
  return (
    <div className="body_wrap">
      <ServicesCard />
      <ContactUsBannerCard
        theme="dark"
      />
    </div>
  )
}

export default ServicesPage

