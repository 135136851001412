import { useEffect, useState } from "react"
/* import { IoImage } from "react-icons/io5" */
import { FaCar } from "react-icons/fa"
import { FaIndustry } from "react-icons/fa"
import { GiWoodBeam } from "react-icons/gi"
import { FaCarrot } from "react-icons/fa"

const ServiceCard = () => {
	const [showTitle, setShowTitle] = useState(false);
  const [showWrap, setShowWrap] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const targetElement = document.getElementById('SERVICE_CARD_WRAP');
      const rect = targetElement.getBoundingClientRect();
      const isInViewport = rect.top >= 0 && rect.bottom + 500 <= window.innerHeight;

      if (rect.bottom + 500 <= window.innerHeight) {
        setShowTitle(true);
        setShowWrap(true);
      }

      if (isInViewport) {
        setShowTitle(true);
        setShowWrap(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /* const updateBackgroundHeight = () => {
    const serviceCardWrap = document.getElementById("SERVICE_CARD_WRAP");
    if (serviceCardWrap) {
      document.getElementById("SERVICE_CARD_BACKGROUND").style.height = `${serviceCardWrap.offsetHeight + 450}px`;
    }
  };
 */
  /* useEffect(() => {
    updateBackgroundHeight();
  }, [showWrap]); */

  /* useEffect(() => {
    window.addEventListener('resize', updateBackgroundHeight);
    return () => {
      window.removeEventListener('resize', updateBackgroundHeight);
    };
  }, []); */

	return (
		<div className="service_card">
			{/* <div className="service_card_background" id="SERVICE_CARD_BACKGROUND"></div> */}
			<div className="service_card_wrap">
				{ showTitle &&
					<h2 className="service_title">
						Ponúkame mobilné čistenie suchým ľadom ktoré viete využiť v rôznych oblastiach priemyslu ale aj v domácnosti.
					</h2>
				}
				{/* <div className="service_descr">
					Ponúkame mobilné čistenie suchým ľadom ktoré viete využiť v rôznych oblastiach priemyslu ale aj v domácnosti.
				</div> */}
				<div className="service_content_wrap" id="SERVICE_CARD_WRAP">

					{
						showWrap &&
						<div className="service_element">
							<div className="img_wrap">
								<img src={require("../assets/images/advantage_1.png")} alt="Arctic Clean | Čistenie áut a jeho súčastí" />
								<FaCar
									size={"80px"}
									color="#6C777F"
									style={{ zIndex: 100}}
								/>
							</div>
							<div className="description_wrap">
								<h2>Čistenie áut a jeho súčastí</h2>
								<div>
								Tryskanie suchým ľadom je moderná a účinná metóda, ktorá dokáže efektívne odstrániť nečistoty z interiéru aj exteriéru automobilov.
								Tento proces je vhodný na všetky komponenty a materiály, vďaka čomu je univerzálnym riešením pre rôzne typy vozidiel.
								</div>
							</div>
						</div>
					}

					{
						showWrap &&
						<div className="service_element service_element_right">
							<div className="img_wrap">
								<img src={require("../assets/images/advantage_4.png")} alt="Arctic Clean | Čistenie priemyselných strojov" />
								<FaIndustry
									size={"80px"}
									color="#6C777F"
									style={{ zIndex: 100}}
								/>
							</div>
							<div className="description_wrap">
								<h2>Čistenie priemyselných strojov</h2>
								<div>
									Tryskanie suchým ľadom sa tiež využíva na odstraňovanie nečistôt a zvyškov z rôznych povrchov.
									Táto metóda je ideálna nielen pri čistení starych a znečistených priemyselných strojov,
									ale tiež pri údržbe elektrických častí a rozličných zariadení. Suchý ľad sa pri tomto procese
									mení priamo z tuhého skupenstva na plyn, čo umožňuje efektívne odstránenie nečistôt bez použitia vody
									alebo chemických čističov.
								</div>
							</div>
						</div>
					}

					{
						showWrap &&
						<div className="service_element">
							<div className="img_wrap">
							<img src={require("../assets/images/advantage_3.png")} alt="Arctic Clean | Čistenie v stavebnom a drevárenskom priemysle" />
								<GiWoodBeam
									size={"80px"}
									color="#6C777F"
									style={{ zIndex: 100}}
								/>
							</div>
							<div className="description_wrap">
								<h2>Čistenie v stavebnom a drevárenskom priemysle</h2>
								<div>
									Suchý ľad účinne odstraňuje nečistoty, machy a plesne z materiálov ako napríklad betón,
									kameň, tehla alebo drevo, fasád a zachováva ich pôvodný vzhľad.
								</div>
							</div>
						</div>
					}

					{
						showWrap &&
						<div className="service_element service_element_right">
							<div className="img_wrap">
								<img src={require("../assets/images/advantage_2.png")} alt="Arctic Clean | Využitie v potravinárskom priemysle" />
								<FaCarrot
									size={"80px"}
									color="#6C777F"
									style={{ zIndex: 100}}
								/>
							</div>
							<div className="description_wrap">
								<h2>Využitie v potravinárskom priemysle</h2>
								<div>
									Čistenie suchým ľadom je v potravinárskom priemysle veľmi užitočné.
									Táto metóda sa využíva na čistenie chladiarenských a mraziarenských boxov, kde efektívne
									odstraňuje nečistoty a zároveň nezanecháva žiadne zvyšky čistiacich prostriedkov,
									čo je dôležité pre bezpečnosť potravín. Okrem toho sa suchým ľadom tiež dezinfikujú a
									čistia výrobné priestory od nečistôt a plesní, čím sa zabezpečuje hygienické a bezpečné
									prostredie pre výrobu potravín.
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default ServiceCard

