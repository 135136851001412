import { useEffect } from "react"
import AboutUsPageContentCard from "../components/AboutUsPageContentCard"
import ContactUsBannerCard from "../components/ContactUsBannerCard"

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0,0)
    document.getElementsByTagName("META")[4].content =`Sme firma ktorá sa zaoberá čistením povrchov pomocou tryskania suchého ľadu. Taktiež ponúkame mobilné čistenie interiérov a exteriérov áut, tepovanie a prípravu áut na predaj.`
    document.getElementsByTagName("LINK")[4].href =`https://www.arcticclean.sk/o_nas`
  }, [])
  return (
    <div className="body_wrap">
      <AboutUsPageContentCard />
      <ContactUsBannerCard
        intro="Zodpovieme všetky vaše otázky"
        theme="dark"
      />
    </div>
  )
}

export default AboutUsPage

