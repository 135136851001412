import { useEffect, useState } from "react"
/* import { BsImageAlt } from "react-icons/bs"; */
import { IoImage } from "react-icons/io5";

const ProjectsCard = () => {

  const [showTitle, setShowTitle] = useState(false)
  const [showWrap, setShowWrap] = useState(false)

  useEffect(() => {

      const handleScroll = () => {
        const targetElement = document.getElementById('PROJECTS_CARD_ID')
        const rect = targetElement.getBoundingClientRect()
        const isInViewport = rect.top >= 0 && rect.bottom - 300 <= window.innerHeight


				if (rect.bottom - 300 <= window.innerHeight) {
					setShowTitle(true)
          setShowWrap(true)
				}
        if (isInViewport) {
          /* targetElement.style.minHeight = "40vh" */
          // Optional: Remove the event listener after the alert is triggered to prevent multiple alerts
          setShowTitle(true)
          setShowWrap(true)
          window.removeEventListener('scroll', handleScroll)
        }
      }
		handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])


	return (
		<div className="projects_card" id="PROJECTS_CARD_ID">
			{
			showTitle &&
				<h1 id="PROJECTS_CARD_TITLE_ID">
					Naše projekty
				</h1>
			}
			<div className="projects_wrap">

			{
				showWrap &&
				<div className="project_element">
					<div
						className="project_element_img"
					>
						<IoImage size={"180px"} color="#001524"/>
						<img
							loading="lazy"
							fetchPriority="high"
							src={require("../assets/images/work/preview_4.png")}
							alt="Arctic Clean | Čistenie dverového mechanizmu"
						/>
					</div>
					<div className="project_element_descr">
						<h3>Čistenie dverového mechanizmu</h3>
						Čistý dverový mechanizmus zlepšuje otváranie a zatváranie dverí tým, že odstraňuje nečistoty a mazivá, ktoré môžu ovplyvniť funkčnosť zámkov a mechanických komponentov.
						Pravidelné čistenie pomáha predchádzať opotrebeniu a zabezpečuje dlhšiu životnosť týchto častí.
					</div>
				</div>
			}

			{
				showWrap &&
				<div className="project_element">
					<div className="project_element_img">
						<IoImage size={"180px"} color="#001524"/>
						<img
							loading="lazy"
							src={require("../assets/images/work/preview_1.png")}
							alt="Arctic Clean | Čistenie motorového priestoru"
						/>
					</div>
					<div className="project_element_descr">
						<h3>Čistenie motorového priestoru</h3>
						Čistý motorový priestor zlepšuje chladenie a vzduchový tok okolo kritických komponentov,
						čím udržuje optimálnu teplotu motora a zabezpečuje spoľahlivejšiu prevádzku vozidla.
					</div>
				</div>
			}

			{
				showWrap &&
				<div className="project_element">
					<div className="project_element_img">
						<IoImage size={"180px"} color="#001524"/>
						<img
							loading="lazy"
							src={require("../assets/images/work/preview_3.png")}
							alt="Arctic Clean | Odstraňovanie olejových škvŕn"
						/>
					</div>
					<div className="project_element_descr">
						<h3>Odstraňovanie olejových škvŕn</h3>
						Odstraňovanie olejových škvŕn suchým ľadom je efektívna metóda bez použitia chemických čistiacich prostriedkov. Tuhý oxid uhličitý účinne čistí kontaminované povrchy, obnovuje ich pôvodný vzhľad (ako betón, asfalt alebo kovy) a bez zanechania reziduí. Týmto prispieva k zlepšeniu bezpečnosti a estetiky prostredia.
					</div>
				</div>
			}
			</div>

		{/*
			<div className="projects_wrap">

				<div className="project_element">
					<div className="project_element_img">
						image
					</div>
					<div className="project_element_descr">
						Project 1
					</div>
				</div>


				<div className="project_element">
					<div className="project_element_img">
						image
					</div>
					<div className="project_element_descr">
						Project 1
					</div>
				</div>


				<div className="project_element">
					<div className="project_element_img">
						image
					</div>
					<div className="project_element_descr">
						Project 1
					</div>
				</div>
			</div> */}
		</div>
	)
}

export default ProjectsCard

