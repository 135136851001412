import { FaPhone } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { FaLocationArrow } from "react-icons/fa";
import { GoHomeFill } from "react-icons/go";
import { FaInfoCircle } from "react-icons/fa";
import { MdCleaningServices } from "react-icons/md";
import { openSocial, phone } from "../config/config";

import { useEffect, useState } from "react";


const Header = (props) => {
	const [menuOpen, setMenuOpen] = useState(false)
	const [firstRender, setFirstRender] = useState(true)
	const navigate = useNavigate()

	const localMenuClass =
		menuOpen
			? "mobile_view_wrap_open"
			: firstRender ? "mobile_view_wrap_closed_first_render" : "mobile_view_wrap_closed"

	const [menuClass, setMenuClass] = useState(menuOpen ? "mobile_view_wrap_open" : "" )

	useEffect(() => {
		if (firstRender && menuOpen) {
			setMenuClass(localMenuClass)
		}
		setFirstRender(false)

		/* setMenuClass(localMenuClass) */
	}, [firstRender, localMenuClass, menuOpen])
	useEffect(() => {
		setMenuClass(localMenuClass)
	}, [menuOpen, localMenuClass])

	return (
		<>
			<header>
				<div className={`mobile_view_wrap ${menuClass}`} id="MOBILE_VIEW_WRAP">
					<div className="hamburger " >
						<FaLocationArrow  size={"35px"} className="icon" onClick={() => { setMenuOpen(prev => !prev) }}/>
						<div className="home_icon" onClick={() => {navigate("/"); setMenuOpen(false)}}>
							<GoHomeFill  size={"35px"} color="white"/>
							Domov
						</div>
						<div className="about_us_icon" onClick={() => {navigate("/o_nas"); setMenuOpen(false)}}>
							<FaInfoCircle  size={"35px"} color="white"/>
							O nás
						</div>
						<div className="service_icon" onClick={() => {navigate("/sluzby"); setMenuOpen(false)}}>
							<MdCleaningServices   size={"35px"} color="white"/>
							Služby
						</div>

					</div>
				</div>
				<div className="header_list">
					<ul>
						<li onClick={() => {navigate("/")}}>Domov</li>
						<li onClick={() => {navigate("/o_nas")}}>O nás</li>
						<li onClick={() => {navigate("/sluzby")}}>Služby</li>
					</ul>
				</div>
				<div className="main_logo_wrap">
					<img src={require("../assets/images/logo.png")} alt="Arctic Clean | Čistenie suchým ľadom" />
				</div>
				<div className="action_bar_wrap">
					<div className="call_me_wrap">
						<button onClick={() => {openSocial("phone")}}>
							<FaPhone className="phone_icon" size={"25px"}/>
							<span>
								<b>{phone}</b>
							</span>
						</button>
					</div>
				</div>
			</header>
		</>
	);
}

export default Header;

