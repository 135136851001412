import { useEffect, useState } from "react"
import { FaSquareFacebook } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { FaPhoneFlip } from "react-icons/fa6";
import { openSocial } from "../config/config";

const ContactUsBannerCard = (props) => {

  const [showIcon, setShowIcon] = useState(false)

  useEffect(() => {

      const handleScroll = () => {
        const targetElement = document.getElementById('CONTACT_US_BANNER_ID');
        const rect = targetElement.getBoundingClientRect();
        const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;


        if (isInViewport) {
          /* targetElement.style.minHeight = "40vh" */
          // Optional: Remove the event listener after the alert is triggered to prevent multiple alerts
          setShowIcon(true)
          window.removeEventListener('scroll', handleScroll);
        }
      };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      setShowIcon(false)
    };
  }, []);

	/* useEffect(() => {
		const contactUsCardWrap = document.getElementById("CONTACT_US_BANNER_BG")
		document.getElementById("CONTACT_US_BANNER_CONTENT").style.height = `${contactUsCardWrap.offsetHeight}px`
    contactUsCardWrap.style.height = `${contactUsCardWrap.offsetHeight}px`
	}, []) */

  return (
    <div className={`contact_us_banner`} id="CONTACT_US_BANNER_ID">
      {/* <div className={`contact_us_banner_bg_image ${props.theme === "light" ? "contact_us_banner_light_theme" : "contact_us_banner_dark_theme"}`} id="CONTACT_US_BANNER_BG"></div> */}
      <div className={`contact_us_banner_content ${props.theme === "light" ? "contact_us_banner_light_theme" : "contact_us_banner_dark_theme"}`} id="CONTACT_US_BANNER_CONTENT">
        <span className="title">
          {props.intro ? props.intro : "Potrebujete s niečim poradiť ?" }
        </span>
        <span className="call_us">
          Kontaktujte nás
        </span>
        <div className="social">
        {
        showIcon &&
            <>
              <FaSquareFacebook
                className="icon"
                color={props.theme === "light" ? "#001524" : "#6C777F"}
                size={"45px"}
                style={{ zIndex: 100}}
                onClick={() => {openSocial("facebook")}}
              />
              <AiFillInstagram
                className="icon"
                color={props.theme === "light" ? "#001524" : "#6C777F"}
                size={"45px"}
                style={{ zIndex: 100}}
                onClick={() => {openSocial("instagram")}}
              />
              <MdEmail
                color={props.theme === "light" ? "#001524" : "#6C777F"}
                className="icon"
                size={"45px"}
                style={{ zIndex: 100}}
                onClick={() => {openSocial("email")}}
              />
              <FaPhoneFlip
                color={props.theme === "light" ? "#001524" : "#6C777F"}
                className="icon"
                size={"45px"}
                style={{ zIndex: 100}}
                onClick={() => {openSocial("phone")}}
              />
            </>
          }
          </div>
      </div>
    </div>
  )
}

export default ContactUsBannerCard