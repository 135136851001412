
import {Outlet} from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FaArrowTurnUp } from "react-icons/fa6";

import { useEffect, useState } from "react";

/* import { AiFillHome } from "react-icons/ai";
import { FaCircleInfo } from "react-icons/fa6";
import { GrProjects } from "react-icons/gr";
import { MdHomeRepairService } from "react-icons/md"; */


const Default = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [initLoad, setInitLoad] = useState(true)
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {

    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const handleMenuStatusChange = () => {
    setIsMenuOpen((prevState) => !prevState)
  }

  const [menuStyle, setMenuStyle] = useState(isMenuOpen
    ? "visible_menu box_shadow"
    : (initLoad)
      ? "hidden_menu_first_load"
      : "hidden_menu box_shadow")

  /* const menuStyle = "visible_menu box_shadow" */

  const [ulClassName, setIlClassName] = useState(menuStyle)

  useEffect(() => {
    setInitLoad(false)
    setIlClassName(menuStyle)
    if(initLoad) {
      setTimeout(() => {
        setIsMenuOpen(true)
      }, 200);
    }
    setMenuStyle(isMenuOpen
      ? "visible_menu box_shadow"
      : (initLoad)
        ? "hidden_menu_first_load"
        : "hidden_menu box_shadow")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen])

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    const scrollUpButton = document.getElementById("SCROLL_UP_BUTTON")
    const mobileMenu = document.getElementById("MOBILE_VIEW_WRAP")
    setTimeout(() => {
      scrollUpButton.style.animationDuration = "0.5s"
      mobileMenu.style.animationDuration = "0.6s"
    }, 500);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      <Header
        changeMenuStatus={handleMenuStatusChange}
      />
			<div className="content_wrap" key={ulClassName}>

        <Outlet />
      </div>
      <Footer />
      <FaArrowTurnUp
        id="SCROLL_UP_BUTTON"
        className={`scroll_up_button ${isVisible ? "scroll_up_button_visible" : "scroll_up_button_hidden"}`}
        color="#001524"
        size={"30px"}
        onClick={scrollToTop}
      />
    </>
  );
};

export default Default;

