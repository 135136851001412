
import IntroCard from "../components/IntroCard"
import MapCard from "../components/MapCard"
import ProjectsCard from "../components/ProjectsCard"
import ServiceCard from "../components/ServiceCard"
import ContactUsBannerCard from "../components/ContactUsBannerCard"
import { useEffect } from "react"
const HomePage = () => {

  useEffect(() => {
    document.getElementsByTagName("META")[4].content =`Ponúkame mobilné čistenie suchým ľadom ktoré viete využiť v rôznych oblastiach priemyslu ale aj v domácnosti. Neváhajte nás kontaktovať na +421 915 621 900.`
    document.getElementsByTagName("LINK")[4].href =`https://www.arcticclean.sk/`
  }, [])
  return (
    <div className="body_wrap">
      <IntroCard />
      <ServiceCard />
      <ProjectsCard />

      <MapCard />
      <ContactUsBannerCard
        theme="light"
      />
    </div>
  )
}

export default HomePage

