import { useEffect, useState } from "react";
import Card from "./Card";
import { openSocial } from "../config/config";
import { IoCall } from "react-icons/io5";


const IntroCard = () => {
    const [element, setElement] = useState(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        window.scrollTo(0,0)
      }, [])

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            const entry = entries[0];
            if (entry && entry.contentRect) {
                setDimensions({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height
                });
            }
        });

        const targetElement = document.getElementById("INTRO_CARD");
        if (targetElement) {
            resizeObserver.observe(targetElement);
            setElement(targetElement);
        }

        return () => {
            if (targetElement) {
                resizeObserver.unobserve(targetElement);
            }
        };
    }, []);

    return (
        <>
            <div className="intro_card" id="INTRO_CARD">
                {element && (
                    <>
                        <Card key={element} width={dimensions.width} height={dimensions.height} />
                    </>
                )}
                <div className="intro_card_content">
                    <div className="logo">
                        <img src={require("../assets/images/logo.png")} alt="Arctic Clean" />
                    </div>
                    <h1>
                        ČISTENIE <br /> SUCHÝM ĽADOM
                    </h1>
                    <p>
                        Zameriavame sa na efektívne odstraňovanie nečistôt a kontaminácií z rôznych
                        povrchov bez použitia chemických čistiacich prostriedkov.
                    </p>
                    <p>
                        Používame tuhý oxid uhličitý na odstránenie olejových škvŕn,
                        mastných zvyškov a iných nečistôt, čím sa obnový pôvodný vzhľad materiálov a zabezpečí sa bezpečné, ekologické a šetrné čistenie.
                    </p>

                </div>
                <div className="cta_card" onClick={() => {openSocial("phone")}}>
                    <span>Objednať čistenie</span>
                </div>
            </div>

            <div className="intro_card_mobile">
                <div className="intro_card_header_wrap">
                    <div className="intro_card_header_content">
                        <div className="logo">
                            <img src={require("../assets/images/logo_light.png")} alt="Arctic Clean" />
                        </div>
                        <div className="cta_card" onClick={() => {openSocial("phone")}}>
                            <IoCall
                                className="icon"
                                size={25}
                            />
                            <span>Objednať čistenie</span>
                        </div>
                    </div>
                </div>
                <div className="intro_card_mobile_content">
                    <div className="image_wrap">
                        <div className="img"></div>
                        <h1>Mobilné čistenie suchým ľadom</h1>
                    </div>
                    <div className="text_wrap">
                        <h2>
                            Kto sme
                        </h2>
                        <p>
                            Sme firma ktorá sa zaoberá čistením povrchov pomocou tryskania suchého ľadu.
                            Taktiež ponúkame mobilné čistenie interiérov a exteriérov áut, tepovanie a prípravu áut na predaj.
                        </p>
                        <p>
                            Vaša spokojnosť je našou prioritou, preto je samozrejmosťou profesionálny prístup a rýchle dodanie služieb.
                        </p>
                    </div>
                </div>
            </div>

        </>
    );
};

export default IntroCard;
