import { FaFacebookF } from 'react-icons/fa6';
import { BiLogoInstagramAlt } from 'react-icons/bi';
import { BiLogoGmail } from 'react-icons/bi';
import { BsGoogle } from 'react-icons/bs';
import { FaCopy } from "react-icons/fa6";
import { openSocial, phone, email, name, ico, dic, address_1, /* address_2 */ } from "../config/config";

const Footer = () => {
  const logo = require('../assets/images/logo_footer_dark.svg').default;

	const copyToClipBoard = (string) => {
		try {
			navigator.clipboard.writeText(string)
		} catch {
			console.log("error while trying to copy to clipboard")
		}
	}

  return (
    <footer>
      {/* <div className='footer_background'></div> */}
      <div className='footer_content'>
        <div className='logo'>
					<img
						src={logo}
						alt='Arctic Clean | Čistenie suchým ľadom'
					/>
				</div>
        <div className='footer_content_layout'>

					<div className='contact_wrap'>
						<div className='info'>
							<div className='owner'>
								<div className='title'>Prevádzkovateľ</div>
								<div className='owner_data'>{name}</div>
								<div className='owner_data'>IČO: {ico}</div>
								<div className='owner_data'>DIČ: {dic}</div>
								<div className='owner_data'>{address_1}{/* <br />
								{address_2} */}</div>
							</div>
							<div className='contact_us'>
								<div className='title'>Kontakt</div>
								<div className='contact'>{email}<FaCopy className='icon' onClick={() => {copyToClipBoard(email)}}/></div>
								<div className='contact'>{phone}</div>
							</div>
						</div>
						<div className='CTA'>
							<div className='title'>Sociálne siete</div>
							<div className="content">
								<div className='social'>
									<img
										src={require('../assets/images/social_2.png')}
										alt=''
									/>
									<FaFacebookF
										className='icon'
										style={{ zIndex: 100 }}
										onClick={() => {openSocial("facebook")}}
									/>
								</div>
								<div className='social'>
									<img
										src={require('../assets/images/social_1.png')}
										alt=''
									/>
									<BiLogoInstagramAlt
										className='icon'
										style={{ zIndex: 100 }}
										onClick={() => {openSocial("instagram")}}
									/>
								</div>
								<div className='social'>
									<img
										src={require('../assets/images/social_3.png')}
										alt=''
									/>
									<BiLogoGmail
										className='icon'
										style={{ zIndex: 100 }}
										onClick={() => {openSocial("email")}}
									/>
								</div>
								<div className='social'>
									<img
										src={require('../assets/images/social_3.png')}
										alt=''
									/>
									<BsGoogle
										className='icon'
										style={{ zIndex: 100 }}
										onClick={() => {openSocial("google")}}
									/>
								</div>
							</div>
						</div>
					</div>
          <div className='info_section'>

						<div className='google_maps_section'>
							<iframe
								className='map'
								title='ArcticClean'
								src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d668602.4863943578!2d20.558061266417624!3d49.11079877112614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473e8c1b806f52ff%3A0x100f7d1c6978c50!2zUHJlxaFvdnNrw70ga3Jhag!5e0!3m2!1ssk!2ssk!4v1719327973796!5m2!1ssk!2ssk'
								allowfullscreen=''
								referrerpolicy='no-referrer-when-downgrade'
								style={{
									width: "100%",
									height: "240px",
									border: 0,
									borderRadius: "12px",
								}}
							></iframe>
						</div>
          </div>

        </div>
        {/* <div className="copyright">
                    C ArcticClean 2042
                </div> */}
      </div>
    </footer>
  );
};

export default Footer;
