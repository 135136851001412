/* import { BsImageAlt } from "react-icons/bs"; */
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { MdOutlineCleaningServices } from "react-icons/md";
import { FaCarOn } from "react-icons/fa6";

const AboutUsPageContentCard = () => {

  return(
    <div className="about_us_page_content_body">
      <div className="about_us_intro" id="ABOUT_US_INTRO">
        <h1>
          Kto sme
        </h1>
        <h2>
        Sme firma ktorá sa zaoberá čistením povrchov pomocou tryskania suchého ľadu. Taktiež ponúkame mobilné čistenie interiérov a exteriérov áut, tepovanie a prípravu áut na predaj.
        </h2>
      </div>
      <div className="about_us_mission">
        <div className="text">
          <h1>
            Na čo sa zameriavame
          </h1>
          <p>
            Naša služba čistenia suchým ľadom sa zameriava na efektívne odstraňovanie nečistôt a kontaminácií z rôznych
            povrchov bez použitia chemických čistiacich prostriedkov. Používame tuhý oxid uhličitý na odstránenie olejových škvŕn,
            mastných zvyškov a iných nečistôt, čím sa obnový pôvodný vzhľad materiálov a zabezpečí sa bezpečné, ekologické a šetrné čistenie.
          </p>
          <p>
            V blízkej budúcnosti plánujeme rozšíriť naše služby aj o čistenie vysokotlakovými čističmi a tryskanie pieskom.
          </p>
          <p>
            Pôsobíme prevažne na východnom Slovensku, no naše služby ponúkame aj v rámci celého Slovenska.
          </p>
        </div>
        <div className="images">
          <div className="first_row">
            <div className="project_element_img">
              <MdOutlineAccessTimeFilled size={"150px"} color="#001524"/>
            </div>
            <div className="project_element_img">
              <MdOutlineCleaningServices size={"150px"} color="#001524"/>
            </div>
          </div>
          <div className="second_row">
            <div className="project_element_img">
              <FaCarOn size={"150px"} color="#001524"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUsPageContentCard