const Card = (props) => {

  const leftSideStepTwoHeight = props.height - 75
  const leftSideStepOneHeight = leftSideStepTwoHeight - 75

  const leftSideStepOneWidth = 300
  const leftSideStepTwoWidth = 480

  /* const rightSideStepOneWidth = props.width - 250
  const rightSideStepOneHeight = props.height - 250 */

  const rightSideStepOneWidth = props.width - 350
  const rightSideStepOneHeight = props.height - 135

  const radius = 32
  return (
    <>
    {/* {props.width} */}
      <svg className="SVG_BACKGROUND" key={props.key} width={props.width} height={props.height} /* viewBox={`0 0 2842 1117`}  */fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* <path
          d={`M0 40C0 17.9086 17.9086 0 40 0H2402C2424.09 0 2442 17.9086 2442 40V794C2442 816.091 2424.09 834 2402 834H1947.11C1925.01 834 1907.11 851.909 1907.11 874V1077C1907.11 1099.09 1889.2 1117 1867.11 1117H942.325C920.233 1117 902.325 1099.09 902.325 1077V1074C902.325 1051.91 884.416 1034 862.325 1034H581.253C559.162 1034 541.253 1016.09 541.253 994V976.5C541.253 954.409 523.345 936.5 501.253 936.5H40C17.9086 936.5 0 918.591 0 896.5V40Z`}
          fill="white"
        /> */}
        <defs>
          <pattern id="imagePattern" patternUnits="userSpaceOnUse" width={props.width} height={props.height}>
            <image className="background_image" href={require("../assets/images/bg.jpg")} x="0" y="0" width={props.width}/*  height={props.height} */ />
          </pattern>
        </defs>
        <path
          d={`M26 0
          H${props.width - radius}
          Q${props.width} 0, ${props.width} ${radius}
          V${rightSideStepOneHeight - radius}
          Q${props.width} ${rightSideStepOneHeight + radius}, ${props.width - radius*2} ${rightSideStepOneHeight + radius}
          H${rightSideStepOneWidth + radius}
          Q${rightSideStepOneWidth - 0} ${rightSideStepOneHeight + radius}, ${rightSideStepOneWidth} ${rightSideStepOneHeight + radius*2}
          V${props.height - radius}
          Q${rightSideStepOneWidth} ${props.height}, ${rightSideStepOneWidth - radius} ${props.height}
          H${leftSideStepTwoWidth + radius}
          Q${leftSideStepTwoWidth} ${props.height}, ${leftSideStepTwoWidth} ${props.height - radius}
          V${leftSideStepTwoHeight + radius}
          Q${leftSideStepTwoWidth} ${leftSideStepTwoHeight}, ${leftSideStepTwoWidth - radius} ${leftSideStepTwoHeight}
          H${leftSideStepOneWidth + radius}
          Q${leftSideStepOneWidth} ${leftSideStepTwoHeight}, ${leftSideStepOneWidth} ${leftSideStepTwoHeight - radius}
          V${leftSideStepOneHeight + radius}
          Q${leftSideStepOneWidth} ${leftSideStepOneHeight}, ${leftSideStepOneWidth - radius} ${leftSideStepOneHeight}
          H${radius}
          Q0 ${leftSideStepOneHeight}, 0 ${leftSideStepOneHeight - radius}
          V${radius}
          Q0 0, ${radius} 0
          Z`}
          fill="url(#imagePattern)"
        />
        {/* <path
          d={`M26 0
          H${props.width - radius}
          Q${props.width} 0, ${props.width} ${radius}
          V${rightSideStepOneHeight - radius}
          Q${props.width} ${rightSideStepOneHeight + radius}, ${props.width - radius*2} ${rightSideStepOneHeight + radius}
          H${rightSideStepOneWidth + radius}
          Q${rightSideStepOneWidth - 0} ${rightSideStepOneHeight + radius}, ${rightSideStepOneWidth} ${rightSideStepOneHeight + radius*2}
          V${props.height - radius}
          Q${rightSideStepOneWidth} ${props.height}, ${rightSideStepOneWidth - radius} ${props.height}
          H${leftSideStepTwoWidth + radius}
          Q${leftSideStepTwoWidth} ${props.height}, ${leftSideStepTwoWidth} ${props.height - radius}
          V${leftSideStepTwoHeight + radius}
          Q${leftSideStepTwoWidth} ${leftSideStepTwoHeight}, ${leftSideStepTwoWidth - radius} ${leftSideStepTwoHeight}
          H${leftSideStepOneWidth + radius}
          Q${leftSideStepOneWidth} ${leftSideStepTwoHeight}, ${leftSideStepOneWidth} ${leftSideStepTwoHeight - radius}
          V${leftSideStepOneHeight + radius}
          Q${leftSideStepOneWidth} ${leftSideStepOneHeight}, ${leftSideStepOneWidth - radius} ${leftSideStepOneHeight}
          H${radius}
          Q0 ${leftSideStepOneHeight}, 0 ${leftSideStepOneHeight - radius}
          V${radius}
          Q0 0, ${radius} 0
          Z`}
          fill="url(#imagePattern)"
        /> */}
      </svg>
    </>
  )
}

export default Card