import { CgUnavailable } from "react-icons/cg";

const ServicesCard = () => {


  return (
    <div className="services_card_page_content_body">
      <div className="service_card_intro" id="SERVICE_CARD_INTRO">
        <h1>
          Naše služby
        </h1>
        <h2>
          Momentálne máme v ponuke čistenie suchým ľadom a prípravu áut na predaj,
          v blízkej budúcnosti ale plánujeme rozšíriť našu ponuku o tryskanie pieskom a čistenie vysokotlakovými čističmi.
        </h2>
      </div>
      <div className="service_card_content">
        <div className="service_element">
          <img
            src={require("../assets/images/car_prep_dry_ice.jpg")}
            alt="Arctic Clean | Čistenie suchým ľadom"
          />
          <div className="img_overlay"></div>
          <div className="service_headline">
            <span>Čistenie suchým ľadom</span>

          </div>
          <div className="service_descr">
            <div>Čistenie suchým ľadom</div>
            <ul>
              <li>Čistenie automobilov, strojov a elektrických zariadení.</li>
              <li>Čistenie fasád, betónu, dreva a gúm.</li>
              <li>Čistenie chladiarenských boxov a iných potravinárskych zariadení.</li>
            </ul>
          </div>
        </div>

        <div className="service_element">
          <img
            src={require("../assets/images/car_prep.jpg")}
            alt="Arctic Clean | Príprava áut na predaj"
          />
          <div className="img_overlay"></div>
          <div className="service_headline">
            <span>Príprava áut na predaj</span>
          </div>
          <div className="service_descr">
            <div>Príprava áut na predaj</div>
            <ul>
              <li>Čistenie exteriéru, motorovej časti, odtokových plôch, podblatníkov, kolies a okien.</li>
              <li>Čistenie interiéru, tepovanie, ošetrenie plastov a kože.</li>
              <li>Odstránenie náletovej hrdze, vysávanie a umývanie rohoží.</li>
            </ul>
          </div>
        </div>

        <div className="service_element">
          <img
            src={require("../assets/images/tepovanie.png")}
            alt="Arctic Clean | Tepovanie kobercov, čalúnenia a interiéru auta"
          />
          <div className="img_overlay"></div>
          <div className="service_headline">
            <span>Profesionálne tepovanie</span>
          </div>
          <div className="service_descr">
            <div>Profesionálne tepovanie</div>
            <ul>
              <li>Hĺbkové čistenie kobercov a čalúneného nábytku.</li>
              <li>Odstránenie škvŕn, alergénov a pachov s obnovením vzhľadu textilných povrchov.</li>
              <li>Tepovanie interiéru auta, vrátane sedadiel a podláh.</li>
            </ul>
          </div>
        </div>

        <div className="service_element service_element_not_available">
          <img
            src={require("../assets/images/bg.jpg")}
            alt="Arctic Clean | Tryskanie pieskom"
          />
          <div className="img_overlay_not_available"></div>
          <CgUnavailable className="icon_not_available" />
          <div className="service_headline">
            <span>Tryskanie pieskom</span>
          </div>
        </div>

        <div className="service_element service_element_not_available">
          <img
            src={require("../assets/images/bg.jpg")}
            alt="Arctic Clean | Čistenie vysokotlakovým čističom"
          />
          <div className="img_overlay_not_available"></div>
          <CgUnavailable className="icon_not_available" />
          <div className="service_headline">
            <span>Čistenie vysokotlakovým čističom</span>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ServicesCard